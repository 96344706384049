










































import { Component, Prop, Vue } from "vue-property-decorator";
import { FileManager } from "uknow-react-components";
import api from "@/api";
import mainAuth from "@/auth";
import store from "@/store";

@Component({
  components: { FileManager },
})
export default class FileManagerBasic extends Vue {
  get auth() {
    if (localStorage.getItem("msToken") == null) {
      return localStorage.getItem("auth")!.split(" ")[1];
    }
    return null;
  }

  get username() {
    if (localStorage.getItem("msToken") == null) {
      return atob(this.auth!).split(":")[0];
    }
    return null;
  }

  get accessToken() {
    return mainAuth().accessToken;
  }

  get vourusUrl() {
    return store.state.vourusUrl;
  }
}
